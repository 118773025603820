<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
      <el-row style="display:flex;align-items:center">
          <!-- <el-col :span="14"> -->
              <!-- <el-row > -->
          <el-col :span="8">
            <div class="sleepTime">
              <div class="sleep-text">到店次数：</div>
              <div :class="{'active': index === arriveIndex}" class="bt" v-for="(item, index) in arriveData" :key="index" @click="tap_month(index, false, item)">{{item.name}}</div>
            </div>
          </el-col>
          <el-col :span="4">
              <el-input clearable  class="inpu" v-model="form.comeCount"></el-input>
          </el-col>
          <el-col :span="2" class="text-name">次以上</el-col>
          <el-col :span="4">
            <el-form-item :label="$t('userName')" style="margin-bottom:0">
              <el-input clearable  v-model="form.searchCon" placeholder="客户名称/电话"></el-input>
            </el-form-item>
          </el-col>
        <el-col :span="6" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
      </el-form>
    </div>
    <expand-collapse ref="expCol" @change="chooseFold" @click="expCol =!expCol"></expand-collapse>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight - 20"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
					type="index"
          align="center"
					label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="customerNam"
          align="center"
          min-width="120"
					label="客户名称">
				</el-table-column>
				<el-table-column
					prop="phoneNum"
          align="center"
          width="135"
          show-overflow-tooltip
					label="联系电话">
				</el-table-column>
        <!-- <el-table-column
          align="center"
					prop="wechatNum"
					label="微信号">
				</el-table-column> -->
        <el-table-column
          align="center"
					prop="idCard"
          min-width="220"
          show-overflow-tooltip
					label="身份证号">
				</el-table-column>
        <el-table-column
          align="center"
					prop="registTime"
          sortable='custom'
          min-width="180px"
					label="注册时间">
				</el-table-column>
        <el-table-column
          align="center"
					prop="recentlyTime"
          sortable='custom'
          min-width="180px"
					label="最近消费时间">
				</el-table-column>
        <el-table-column
          align="center"
          sortable='custom'
          min-width="160px"
					prop="totalPrice"
					label="消费金额">
				</el-table-column>
        <el-table-column
          align="left"
          width="200px"
					:label="$t('option')">
          <template slot-scope="scope">
            <!-- <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)" >修改</template> -->
            <el-button class="btn-detail" icon="el-icon-s-order" v-if="buttonAuth.includes('venueOperation:customer:detail')" v-show="scope.row.id" @click="openDetailDialog(scope.row)"></el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <el-button class="default-button" v-if="buttonAuth.includes('venueOperation:customer:export')" @click="exportExcelOperation(apis.customermanagerExport, {})">导出</el-button>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- <div class="button-container"> -->
      <!-- <el-button class="default-button" @click="openDetailDialog(selectedArr.map(item => item.userId))">{{$t('delete')}}</el-button> -->
      <!-- <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button> -->
    <!-- </div> -->
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <DetailDialog ref="detailDialog" :show="detailDialog.visible" @closeDialog="changeDetailDialog"></DetailDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import download from '@/mixins/download'
import mixin from '@/mixins/index'
import confirmDialog from '@/components/confirmDialog.vue'
import addDialog from './dialog/addDialog.vue'
import DetailDialog from './dialog/detailDialog.vue'
import OrderRecordSearchClass from './orderRecordSearchClass'
import expandCollapse from '@/components/expandCollapse.vue'

export default {
  components: {
    addDialog,
    DetailDialog,
    confirmDialog,
    expandCollapse,
  },
  mixins: [mixin, download],
  data() {
    return {
      form: new OrderRecordSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      detailDialog: {
        visible: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      monthData: [
        {
          name: "1个月",
          num: 1,
        },
        {
          name: "3个月",
          num: 3,
        },
        {
          name: "6个月",
          num: 6,
        },
      ],
      arriveData: [
        {
          name: "1次",
          num: 1,
        },
        {
          name: "2次",
          num: 2,
        },
        {
          name: "3次",
          num: 3,
        },
      ],
      monthIndex: 0,
      arriveIndex: 0,
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    /**
     * @function 伸缩栏传参
     */
    chooseFold(val) {
      this.expCol = val
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.queryCustomerList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    tap_month(index, type, data) {
      if (type) {
        this.monthIndex = index
        this.form.months = data.num
      } else {
        this.arriveIndex = index
        this.form.comeCount = data.num
      }
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new OrderRecordSearchClass('form')
      this.form.monthIndex = 0
      this.form.arriveIndex = 0
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(this.confirmDialog.type === 1 ? `${apis.userRemove}?ids=${this.confirmDialog.data.ids}` : apis.resetPassword, this.confirmDialog.data).then((res) => {
        if (res.data.code === 0) {
          if (this.confirmDialog.type === 2) {
            this.$confirm(`${this.$t('reset_success')}123456`, '', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确认',
            })
          }
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDetailDialog(id) {
      // this.confirmDialog.data.ids = id.join(',')
      // this.changeConfirmDialog(true, 1)
      this.detailDialog.visible = true
      this.$refs.detailDialog.getDataQuery(id)
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.confirmDialog.data = data
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data = []
        this.searchEvent()
      }
    },
    changeDetailDialog() {
      this.detailDialog.visible = false
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === '1' ? '0' : '1',
        },
      }
      this.$http.post(apis.userStatus, form)
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-container {
  margin: 0;
  height: 50px;
  overflow: hidden;
}
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.tabs-container{
  margin: 0 20px;
}
.text-name {
  font-weight:400;
  color:rgba(21,21,21,1);
  font-size:14px;
  padding-left: 5px;
}
.row-wrap{
  font-size: 14px;
}
.bt{
  // border: 1px solid rgba(228, 233, 241, 1);
  // background: #fff;
  padding: 4px 11px;
  margin-right: 10px;
  cursor: pointer;
font-size:14px;
  // height:28px;
  background:rgba(238,240,244,1);
  border-radius:4px;
  color:rgba(172,174,176,1);
  // height:28px;
  // line-height: 28px;
  border: 1px solid rgba(238,240,244,1);
}
.active{
  font-size:14px;
  color:rgba(68,155,255,1);
  background:rgba(68,155,255,0.3);
  border-radius:4px;
  border:1px solid rgba(68,155,255,1);
}
.sleepTime{
  // margin-left: 5px;
  .sleep-text {
    font-size:14px;
    color:rgba(21,21,21,1);
    padding-left: 20px;
  }
  div{
    display: inline-block;
  }
}
.inpu{
  display: inline-block;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
