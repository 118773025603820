<template>
  <div>
    <div class="exp-col">
      <img v-if="shzk"  src="../assets/images/expand.png" alt="" @click="tack">
      <img class="not-expend" v-else src="../assets/images/collapse.png" alt="" @click="tack">
    </div>
  </div>
</template>
<script>
/**
 * 使用范例
  <expand-collapse ref="expCol" @click="expCol"></expand-collapse>
 */
import mixin from '@/mixins/index'

export default {
  mixins: [mixin],
  data() {
    return {
      shzk: true, // 展开收缩按钮
    }
  },
  methods: {
    /**
     * @function 顶部展开收缩
     */
    tack() {
      if (this.shzk) {
        const data = document.getElementsByClassName('search-container')
        data[0].style.height = 'auto'
        this.shzk = false
        this.$emit('change', this.shzk)
      } else {
        const data = document.getElementsByClassName('search-container')
        data[0].style.height = '40px'
        this.shzk = true
        this.$emit('change', this.shzk)
      }
    },
    searchData() {},
  },
}
</script>
<style lang="less">
// 收缩展开按钮
.exp-col {
  position: relative;
  height:1px;
  background:rgba(238,240,244,1);
  img {
    position: absolute;
    left: 50%;
    margin-left: -21px;
    cursor: pointer;
  }
}

</style>
