// import zh from '@/locales/zh.json'
// import en from '@/locales/en.json'

// const Lang = {
//   zh,
//   en,
// }
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.customerNam = ''
      this.phoneNum = ''
      this.idCard = ''
      this.weChatNum = ''
    } else if (type === 'rule') {
      this.customerNam = [
        {
          required: true,
          message: "请输入客户姓名",
          trigger: 'blur',
        },
      ]
      this.phoneNum = [
        {
          required: true,
          message: "请输入电话号码",
          trigger: 'blur',
        },
      ]
      this.idCard = [
        {
          required: true,
          message: "请输入身份证号码",
          trigger: 'blur',
        },
      ]
      this.weChatNum = [
        {
          required: true,
          message: "请输入微信号",
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
